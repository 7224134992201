<template>
  <div :class="$style.container">
    <h4 :class="$style.title">
      <span>{{ t('title') }}</span>
    </h4>

    <ui-button
      :class="$style.button"
      type="button"
      size="large"
      theme="accent"
      uppercase
      active
      @click="
        $sdk
          .module('auth')
          .then(({ login }) => login())
          .catch(err => {
            console.error(err)
          })
      "
    >
      {{ t('button') }}
    </ui-button>
  </div>
</template>

<i18n>
  {
    "ru": {
      "title": "Авторизуйся чтобы начать",
      "button": "Войти"
    }
  }
</i18n>

<script lang="ts" setup>
import UiButton from '~/components/ui/button/index.vue'

const { t } = useI18n()
</script>

<style lang="scss" module>
.container {
  position: absolute;
  left: 50%;
  bottom: 3em;
  transform: translateX(-50%);
  border-radius: 0.6em;
  padding: 1em 1.2em 1em 2.8em;
  background-color: rgba(white, 0.8);
  box-shadow: 0 1.5em 1.5em rgba(black, 0.1);
  display: flex;
  gap: 5.6em;
  align-items: center;

  @include down(sm) {
    bottom: 50%;
    transform: translate(-50%, 50%);
    padding: 1.2em;
    flex-direction: column;
    gap: 1.2em;
  }
}

.title {
  flex-shrink: 0;
  font-family: var(--primary-font);
  font-weight: 600;
  color: var(--app-color-black);

  span {
    font-size: 1.6em;
  }
}

.button {
  padding-top: 1.2em;
  padding-bottom: 1.2em;
  width: 21.8em;
}
</style>
